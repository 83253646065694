.nav-button-container {
  width: 250px;
  height: 100vh;
  padding: 30% 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.active {
  color: var(--text-primary);
  font-weight: bold;
}
.loading{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(255, 255, 255, 0.445);
}
.layout-container{
  min-height: 50vh;
}
