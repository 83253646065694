.container {
  width: clamp(250px, 90vw, 520px);
  height: 550px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.head {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(33, 33, 33, 0.3215686275);
  justify-content: space-between;
}
.head h1 {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0;
}

.items {
  height: 100%;
  padding: 5px 5px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: rgba(127, 127, 226, 0.0901960784);
}

.footer {
  width: 100%;
  min-height: 50px;
  max-height: 60px;
  padding-top: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid rgba(33, 33, 33, 0.3215686275);
  position: sticky;
  padding-block-start: 1em;
}

.text-warn {
  font-size: 1.2rem;
  color: tomato;
}

.dashed-line {
  width: 100%;
  border-top: 5px dashed var(--text-primary);
  opacity: 0.5;
  margin: 1rem 0px;
}

.buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}