.back-color {
  background: rgb(28, 118, 193);
  background: linear-gradient(
    -90deg,
    rgb(253, 216, 100) 0%,
    rgb(49, 49, 49) 90%,
  );
}
.container {
  width: 100%;
  min-height: calc(100vh - 125px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: url(../../../../assets/home-bg.jpg);
  background-size: cover;
  position: relative;
  z-index: 0;
}

.headings-container {
  display: flex;
  flex-direction: column;
}

.button {
  background: var(--darker-background);
  border-radius: 0;
  color: #fff;
  width: fit-content;
}
.description {
  width: 60vw;
  display: flex;
  padding: 1rem;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
}
h1 {
  font-size: 2.5em;
  line-height: 1em;
}
h5 {
  font-size: 1.25em;
  padding: .5em;
  margin: 0;
}
h1,h5{
  color: var(--text-primary);
  text-shadow: -1px -1px 0 rgb(49, 49, 49), 1px -1px 0 rgb(49, 49, 49), -1px 1px 0 rgb(49, 49, 49), 1px 1px 0 rgb(49, 49, 49);
}
.cover-image{
  width: 40vw;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  // backdrop-filter: blur(2px);
  border-radius: 40px;
  img{
    width: 90%;
    filter: drop-shadow(0px 0px 1px #fff);
  }
}

.cat-text{
  display: flex;
  justify-content: flex-start;
}
.links{
  display: flex;
  flex-direction: column;
}

/***********media query***********/

@media only screen and (max-width: 800px) {
  .container {
    justify-content: center;
    flex-direction: column;
  }
  .description {
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .headings-container {
    align-items: center;
  }
}

.logo {
  width: 300px;
  filter: drop-shadow(1px 1px 1px #aaac3a);
}

@media only screen and  (max-width: 700px){
  .container::before {
    content: '';
    height: 100%;
    aspect-ratio: 630/1080;
    position: absolute;
    background: url(../../../../assets/border.jpg);
    background-size: contain;
    z-index: 1;
    left: 0;
    transform: translateX(calc(75px - 100%));
    top: 0;
  }
  .container::after {
    content: '';
    height: 100%;
    aspect-ratio: 630/1080;
    position: absolute;
    background: url(../../../../assets/border.jpg);
    background-size: contain;
    z-index: 1;
    right: 0;
    transform: translateX(calc(100% - 75px));
    top: 0;
  }
  .container{
    overflow: hidden;
    div{
      z-index: 2;
    }
  }
  .logo{
    width: 50vw;
  }
  .cover-image{
    width: 50vw;
    backdrop-filter: none;
  }
}
@media only screen and  (max-width: 500px){
  h1 {
    font-size: 2.3em;
  }
}
@media only screen and  (max-width: 460px){
  h1 {
    font-size: 2.1em;
  }
}
@media only screen and  (max-width: 430px){
  h1 {
    font-size: 1.9em;
  }
}
