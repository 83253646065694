* {
  color: #fff;
}
a {
  text-decoration: none;
}
.container {
  padding: 25px 15%;
  background: var(--darker-background);
  width: 100%;
  position: relative;
  z-index: 0;
}
.container::before{
  content: "";
  position: absolute;
  z-index: -1;
  inset: 0;
  background: url(../../../assets/logo/footer-white.svg) top 0 left 0 repeat, url(../../../assets/logo/footer-white.svg) top 160px left 160px repeat;
  opacity: .05;
  background-size: 320px auto;
}
.container-footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 20px;
  border-bottom: 1px solid;
}
.links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.links .title-list h2 {
  margin: 0;
  font-size: 1.2em;
  font-weight: 700;
  letter-spacing: 1px;
}
.description {
  max-width: clamp(200px, 70%, 900px);
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.header .img-bx {
  width: 90px;
}
.header .img-bx img {
  width: 100%;
  aspect-ratio: 1/1;
}
.header h1 {
  margin: 0;
  margin-left: 15px;
}
.icons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 20px 0;
  width: 100%;
}
.icons h5 {
  width: 100%;
  text-align: center;
  margin-block: 10px;
}
.icons a img {
  width: 20px;
  aspect-ratio: 1/1;
  margin: 0 8px;
}
.text-footer {
  display: flex;
  justify-content: center;
}

/**************** media query ****************/
@media only screen and (max-width: 800px) {
  .header h1 {
    font-size: 1.7em;
  }
  .header .img-bx {
    width: 80px;
  }
  .description {
    max-width: 100%;
    margin-block-end: 20px;
  }
}
@media only screen and (max-width: 600px) {
  .container-footer {
    justify-content: flex-start;
  }
  .description {
    max-width: 100%;
    margin-block-end: 20px;
  }
  .container {
    padding: 25px 5%;
  }
}
