// ::ng-deep .mat-form-field {
// }
::ng-deep .prof-menu {
  padding: 0;
}
.container {
  width: 100%;
  height: 125px;
  position: fixed;
  top: 0;
  left: 0;
  background: #171717;
  z-index: 2;
}
.menu-btn {
  width: 100%;
  border-radius: 0;
}
.give-margin {
  width: 100%;
  height: 125px;
}
.row-1,
.row-2 {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.row-1 {
  height: 60%;
}
.row-2 {
  height: 40%;
  box-sizing: border-box;
  border-block: 1px solid #5088b750;
  // background: rgba(44, 62, 80, 0.133);
}
.input {
  position: relative;
  width: 100%;
}


.input-con {
  display: flex;
  width: clamp(330px, 40%, 500px);
  margin-inline-start: 10px;
}
.profile-con,
.profile-con2 {
  display: flex;
  align-items: center;
  color: white;
  a {
    padding: 0 4px;
    margin: 0;
    width: auto;
    min-width: 0;
  }
}
.profile-con2 {
  display: none;
}
.ml12 {
  margin-right: 12px !important;
}
.divider {
  display: inline-block;
  height: 30px;
  border-left: 1px solid var(--text-primary);
}
.cart-con {
  width: 25px;
  aspect-ratio: 1/1;
  position: relative;
}
.logo-con {
  display: flex;
  align-items: center;

  h1 {
    color: var(--text-primary);
    margin: 0;
    font-size: 1.5rem;
    line-height: 1.6rem;
  }
  p {
    margin: 0;
    font-size: 0.7rem;
    line-height: 0.8rem;
  }
}
.sub-logo {
  display: flex;
  margin-inline-start: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: var(--text-primary);
}
.logo-img {
  height: 60px;
}
.search-icon {
  height: 44px;
  aspect-ratio: 1/1;
  background-color: var(--text-primary);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.badge {
  position: absolute;
  right: -10px;
  top: -5px;
  z-index: 1;
  padding: 0;
  width: 20px;
  aspect-ratio: 1;
  line-height: 20px;
  justify-content: center;
  align-items: center;
  font-size: 0.6em;
  border-radius: 50%;
  background-color: var(--text-primary);
  color: white;
  overflow: hidden;
  text-overflow: ellipsis;
}
.offer-badge{
  border-radius: 4px;
  background-color: #990d06;
  color: white;
  padding: 0.25em;
  margin-inline: 0.25em;
}
.no-resaults {
  font-size: 1.2em;
  font-weight: 600;
  padding-inline: 2em;
  color: #464646;
}
.res-prod {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.res-name {
  font-weight: bold;
}
.res-price {
  font-size: .8em;
  flex-grow: 1;
  text-align: end;
}
.res-img {
  width: 40px;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  border-radius: 4px;
}

.buttons-con {
  width: clamp(250px, 95%, 700px);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.active {
  color: var(--text-primary);
  font-weight: bold;
}
a {
  transition: 0.2s all;
  min-width: auto;
}
.search-menu {
  width: clamp(300px, 80%, 700px);
  background-color: #ffffff33;
  backdrop-filter: blur(5px);
  border-radius: 4px;
  box-shadow: 2px 2px 5px 2px rgb(0 0 0 / 20%);
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  max-height: 50vh;
  overflow: auto;
}

.mobile-buttons {
  display: none;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

@media only screen and (max-width: 825px) {
  .sub-logo {
    display: none;
  }
  .logo-img {
    height: 40px;
  }
}

@media only screen and (max-width: 500px) {
  .buttons-con,
  .profile-con {
    display: none;
  }
  .mobile-buttons,
  .profile-con2 {
    display: flex;
  }
  .row-1,
  .row-2 {
    justify-content: space-between;
    padding: 0 5%;
  }
}
